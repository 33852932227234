<template>
  <section>
      <!-- <img :src="imagen" v-if="imagen">
      <logo v-if="!imagen" /> -->
      <br><br>
      <slot></slot>
  </section>
</template>

<script>
import dogo from '@/assets/dogo.png'
// import Logo from './Logo.vue'
export default {
//   components: { Logo },
    props: ['imagen'],
    name: 'Caja',
    data() {
        return {
            dogo: dogo
        }
    },
}
</script>

<style scoped>
section {
    text-align: center;
    background: white;
    width: 900px;
    border:1px solid #ccc;
    margin: auto;
    margin-top: 0;
    padding: 30px;
    border-radius: 20px;
}
</style>