<template>
    <caja>
      <h1>Acceso denegado</h1><br>
      <div style="text-align:center">
        <vs-button style="float:none; width:130px;margin:auto" @click="logout" icon=".." icon-pack="fab fa-google">Cerrar sesión</vs-button>
      </div>
    </caja>
</template>

<script>
import Caja from './Caja.vue'
import { logout } from '../session'

export default {
  components: { Caja },
  data() {
    return {
      logout
    }
  },
}
</script>
